<template>
  <div>
    <hr />
    <div class="fw-bold">Rendez-vous</div>

    <div class="row align-items-stratch">
      <div class="col-xl-3 col-6 my-3">
        <label for=""> Date </label>
        <div>
          {{ appointment.date }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Heure </label>
        <div>
          {{ appointment.time }}
        </div>
      </div>
    </div>

    <hr />
    <div class="fw-bold">Réparateur</div>
    <div class="row align-items-stratch">
      <!-- employee -->
      <div class="col-xl-3 my-3">
        <label for=""> Employee</label>
        <div class="" v-if="appointment.employee">
          {{ appointment.employee.fullName }}
        </div>
      </div>
    </div>

    <div class="row align-items-stratch">
      <div class="col-xl-3 col-6 my-3">
        <label for=""> Réparation </label>
        <div v-if="appointment.repair">
          {{ appointment.repair.reference }}
        </div>
      </div>
    </div>

    <hr />
    <div class="fw-bold">Entretiens à effectuer</div>
    <div>
      <div
        class="row align-items-stratch"
        v-for="(repairService, index) in repair.repair_services"
        :key="index++"
      >
        <div class="col-xl-3 col-12 my-3">
          <div v-if="repairService.service">
            {{ index }} - {{ repairService.service.fullName }}
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="fw-bold">Informations client</div>
    <div class="row">
      <div class="col-xl-3 col-6 my-3 my-3">
        <label for=""> Prénom : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.firstName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Nom : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.lastName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">Téléphone : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.phone }}
        </div>
      </div>

      <div class="col-xl-3 col-6">
        <label for=""> </label>
        <div>
          <p v-if="whatsAppUrl(appointment.customer)">
            <a
              :href="whatsAppUrl(appointment.customer)"
              target="_blank"
              class=""
            >
              WhatsApp
            </a>
          </p>
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Fix : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.fix }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">E-mail : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.email }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">Nom Comerciale : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.society }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Adresse : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.address }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Ville : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.city }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Code Postal : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.zipCOde }}
        </div>
      </div>

      <div class="col-xl-3 col-6">
        <label for=""> </label>
        <div>
          <p v-if="googleMapsUrl(appointment.customer)">
            <a
              :href="googleMapsUrl(appointment.customer)"
              target="_blank"
              class=""
            >
              Google Maps</a
            >
          </p>
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto">
        <button class="btn btn-danger" @click="cancel(appointment)">
          <i class="bi bi-x-octagon-fill"></i> Annuler
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="
            $router.push({
              name: 'appointments-validate',
              params: { reference: appointment.reference },
            })
          "
        >
          <i class="bi bi-check-square-fill"></i> Valider
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("appointment", {
      appointment: "getAppointment",
    }),
    ...mapGetters("repair", {
      repairs: "getAll",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("order", {
      orders: "getAll",
    }),
    ...mapGetters("repair", {
      repair: "getRepair",
    }),
  },
  methods: {
    async cancel(data) {
      await this.$store.dispatch("appointment/store", data);
    },

    async validate(data) {
      await this.$store.dispatch("appointment/store", data);
    },

    googleMapsUrl(customer) {
      const { latitude, longitude } = customer;
      if (latitude && longitude) {
        return `https://www.google.com/maps?q=${latitude},${longitude}`;
      }
      return null;
    },

    whatsAppUrl(customer) {
      const { phone } = customer;
      const message = `Hello, ${name}!`;
      let formattedPhone = phone.replace(/^\+/, "");

      if (this.isMobileDevice()) {
        // Open WhatsApp app on mobile
        return `whatsapp://send?phone=${formattedPhone}&text=${encodeURIComponent(
          message
        )}`;
      } else {
        // Open WhatsApp Web on desktop
        return `https://web.whatsapp.com/send?phone=${formattedPhone}&text=${encodeURIComponent(
          message
        )}`;
      }
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  async beforeMount() {
    // await this.$store.dispatch("employee/getAll");
    // await this.$store.dispatch("order/getAll");
    // await this.$store.dispatch("customer/getAll");
    // await this.$store.dispatch("supplier/getAll");
    // await this.$store.dispatch("repair/getAll");
    await this.$store.dispatch(
      "appointment/show",
      this.$route.params.reference
    );

    await this.$store.dispatch(
      "repair/show",
      this.appointment.repair_reference
    );
  },
};
</script>
